import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
    <div style={{ padding: "20px", width: "[90vw]", margin: "0 auto" }}>
    <h1 
  style={{ 
    textAlign: "center", 
    marginBottom: "20px", 
    backgroundColor: "", // Light gray background
    padding: "10px",           // Add padding for better spacing
    fontSize: "2.5rem",        // Increase font size
    fontWeight: "bold",        // Make text bold
    borderRadius: "8px"        // Optional: rounded corners
  }}
>
  Privacy Policy
</h1>

      <p><strong>Last Update:</strong> 21, November, 2024</p>
      <p>
        At <strong>BazaarFinance</strong>, we value your trust and are committed to protecting your information. This Privacy Policy outlines how we collect, use, and safeguard the data within the BazaarFinance app.
      </p>
      
      <h2>1. Information We Collect</h2>
      <ul>
        <li>
          <strong>Document Data:</strong> Financers can access documents uploaded by admins via the web platform for verification purposes.
        </li>
        <li>
          <strong>Financer Data:</strong> Includes details like name, email address, and role for login and identification.
        </li>
        <li>
          <strong>Activity Logs:</strong> Data related to document processing (e.g., Accepted, Rejected, or Additional Information Requested).
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li>
          <strong>Document Verification:</strong> Enabling financers to check and respond to documents uploaded by the admin.
        </li>
        <li>
          <strong>Communication:</strong> Allowing feedback on document status to the admin (Accepted, Rejected, or Ask for More Details).
        </li>
      </ul>
      <p>No data is used for marketing or shared with third parties, except as required by law.</p>

      <h2>3. Data Security</h2>
      <p>We prioritize the security of your information by implementing:</p>
      <ul>
        <li><strong>Encryption:</strong> All data in transit and at rest is encrypted.</li>
        <li><strong>Restricted Access:</strong> Only authorized financers and admins can access the data.</li>
        <li><strong>Secure Storage:</strong> Data is stored on secure servers.</li>
      </ul>
      <p>
        Despite these measures, no system is entirely secure. By using the app, you acknowledge potential risks.
      </p>

      <h2>4. Data Sharing</h2>
      <ul>
        <li><strong>Admin Access:</strong> Admins upload documents that financers review.</li>
        <li>
          <strong>Legal Compliance:</strong> If required by law, we may share data with authorities.
        </li>
      </ul>

      <h2>5. Retention of Data</h2>
      <p>
        We retain data only as long as necessary for document verification. After the process is complete, data is deleted or anonymized, unless retention is required by law.
      </p>

      <h2>6. Your Rights</h2>
      <ul>
        <li><strong>Access Your Data:</strong> Financers can request a copy of their account information.</li>
        <li><strong>Request Deletion:</strong> Financers can request account deletion by contacting us.</li>
        <li><strong>Data Correction:</strong> Notify us of errors, and we’ll correct them promptly.</li>
      </ul>

      <h2>7. Updates to this Policy</h2>
      <p>
        We may revise this Privacy Policy periodically. Any changes will be notified via email or in-app notifications.
      </p>

      <h2>8. Contact Us</h2>
      <p>
        For any privacy-related inquiries, contact us at:  
        <strong>Email:</strong> mkfinancenidhi@gmail.com  
        <strong>Phone:</strong> +917777974466
      </p>
    </div>
    

    <div style={{ padding: "20px", width: "[90vw]", margin: "0 auto" }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Terms & Conditions</h1>
      <p><strong>Last update:</strong>21, November, 2024</p>
      <p>
        Welcome to <strong>BazaarFinance</strong>! These Terms & Conditions govern your use of the BazaarFinance app. By using the app, you agree to these terms.
      </p>

      <h2>1. Service Overview</h2>
      <p>
        The BazaarFinance app provides financers with a platform to:
      </p>
      <ul>
        <li>Review documents uploaded by admins via the web portal.</li>
        <li>Decide on document statuses (Accepted, Rejected, or Ask for More Information).</li>
      </ul>
      <p>
        The app does not handle any monetary transactions or loan approvals. It is solely a document verification tool.
      </p>

      <h2>2. Eligibility</h2>
      <p>
        This app is designed exclusively for authorized financers. Unauthorized use is strictly prohibited.
      </p>

      <h2>3. User Responsibilities</h2>
      <p>Users of the BazaarFinance app are expected to:</p>
      <ul>
        <li>
          <strong>Financers:</strong> Must review documents carefully and provide accurate feedback.
        </li>
        <li>
          <strong>Admins:</strong> Are responsible for ensuring uploaded documents are complete and accurate.
        </li>
      </ul>
      <p>Users must not share login credentials or misuse the app for unauthorized purposes.</p>

      <h2>4. Prohibited Activities</h2>
      <p>Users are strictly prohibited from:</p>
      <ul>
        <li>Uploading fraudulent or inappropriate content.</li>
        <li>Attempting to hack or disrupt app functionality.</li>
        <li>Using the app for any purpose other than document verification.</li>
      </ul>
      <p>Violators may face account suspension or legal action.</p>

      <h2>5. Limitation of Liability</h2>
      <p>BazaarFinance is not responsible for:</p>
      <ul>
        <li>Errors in documents uploaded by the admin.</li>
        <li>Any financial decisions made based on document reviews.</li>
        <li>Disputes between financers and admins.</li>
      </ul>
      <p>Use of the app is entirely at your own risk.</p>

      <h2>6. Data Protection</h2>
      <p>
        Refer to our <a href="/privacy-policy" style={{ color: "blue" }}>Privacy Policy</a> for details on how your data is collected, stored, and secured.
      </p>

      <h2>7. App Updates</h2>
      <p>
        We may update the app or its features without prior notice.
      </p>

      <h2>8. Governing Law</h2>
      <p>
        These Terms are governed by the laws of India. Any disputes will be resolved in the jurisdiction of India.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        For any questions or concerns, contact us at:  
        <strong>Email:</strong> mkfinancenidhi@gmail.com  
        <strong>Phone:</strong> +917777974466
      </p>
    </div>
    </>

  );
};

export default PrivacyPolicy;